.submitBtn {
  margin-top: 20px;
  text-align: center;
}

.radioGroup {
  width: 100%;
  text-align: center;

  :global(.ant-radio-button-wrapper) {
    width: 50%;
  }
}

.similar {
  border-radius: 2px;
  background-color: #f6f7fb;
  padding: 12px !important;
}
